import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import imgMobile from "../../assets/image/jpeg/exo-hair2.jpg";

const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;



const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;


const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 0px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const GummyContentPage = ({setIsModalOpen}) => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section py={4}>
      <Container className="pt-3 pb-3">

          <Row className="pb-4">
              <Col>
                <Iwrap>


<Itext as="h2">ExoMeso <span>London</span></Itext>
</Iwrap>
      <SecondText>
      What is ExoMeso treatment?


      </SecondText>
              </Col>
          </Row>
        <Row className="align-items-center">
          <Col lg="6" className="mb-4  mb-lg-0">
            <div
              className="pl-0"
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img src={imgMobile} alt="What is non surgical rhinoplasty" className="rounded shadow" />
            </div>
          </Col>
          <Col lg="6" md="9">
            <Box className=" pt-lg-0">

              <Text>

                Exo Meso is a specialized treatment designed to combat various types of hair loss, known as alopecia,
                which can affect both men and women. Alopecia is often caused by multiple factors and can occur at
                different stages of life.<br /> <br /> Exo Meso uses a targeted approach rooted in trichology, the
                science of hair and scalp health, to address the underlying causes of hair loss. By promoting repair and
                revitalization, Exo Meso aims to restore hair health, prolong the hair growth cycle, and strengthen hair
                from the root.<br /> <br />

                <b>How Can Exo Meso Benefit You?</b><br /> <br />

                The Exo Meso treatment offers multiple benefits by targeting hair health from various angles. Here’s how
                it can help: <br /> <br />

                Stops Hair Loss: Exo Meso targets the hormonal pathways associated with hair loss, effectively blocking
                the enzyme 5-alpha reductase. This enzyme is responsible for converting testosterone into
                dihydrotestosterone (DHT), a compound known to damage hair follicles. By inhibiting this process, Exo
                Meso helps to normalize hair cycles and delay the onset of alopecia.<br /> <br />
                Promotes Hair Growth: By addressing the root causes of hair loss, including hormone imbalance, scalp
                inflammation, and nutrient deficiency, Exo Meso stimulates the growth of new, healthy hair
                strands.<br /> <br />
                Strengthens Hair: This treatment works to reinforce the hair from the root, making it stronger and more
                resilient. It helps to extend the life cycle of each hair strand, reducing shedding and enhancing
                overall hair density.

              </Text> <br />
              <div onClick={() => setIsModalOpen(true)}
                   activeClassName="active">
                <Bookbutton>Book appointment</Bookbutton>
              </div>
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default GummyContentPage;















