import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";






const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;






const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;

const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
 

}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 2em;
background-color: rgb(171, 142, 102);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: rgb(171, 142, 102);
font-size: 1em;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 16px;
}

`;



const SecondText = styled.h3`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 2.2em;

      margin-bottom: 0.4em;
     
      color: #111;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 16px;
   
      }

`;


const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 100%;
  }
  @media ${device.md} {
    max-width: 100%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const PRPhairBeardContPage = () => (
  <>
    {/* <!-- Content section 2 --> */}
   <Section py={4}>
      <Container className="pt-3 pb-3">

    
        <Row className="align-items-center">
       
          <Col lg="12" md="9" className="0rder-lg-1">
          
          
             

         
              
   <Text>
   <b>Key Actions of the Exo Meso Trichology Range</b> <br /> <br />
Exo Meso’s approach to hair restoration is comprehensive and focuses on five main pathways to combat hair loss effectively:<br /> <br />

Hormonal Balance: By blocking the activity of 5-alpha reductase in hair follicles, Exo Meso helps maintain a healthy hormonal balance, preventing excessive hair loss and prolonging the life of each hair strand.<br /> <br />
Reduction of Sebum Production: Excess sebum can clog hair follicles, leading to follicular death. Exo Meso reduces sebum production, allowing hair to grow in a healthy, unclogged environment.<br /> <br />
Reduced Scalp Inflammation: Scalp inflammation is a common contributor to hair thinning. Exo Meso calms localized inflammation, creating a healthier scalp environment for hair growth.<br /> <br />
Enhanced Scalp Circulation: Improved blood circulation and oxygenation to the scalp encourage nutrient delivery to the hair follicles, fostering healthy hair growth.<br /> <br />
Nutrient Support: Exo Meso supplies essential vitamins, peptides, growth factors, and nutrients to the scalp, addressing metabolic deficiencies that can hinder hair health and strength.<br /> <br />
<b>Why Choose Exo Meso for Hair Restoration?</b><br /> <br />
If you’re seeking a scientifically backed solution to manage hair loss, Exo Meso offers a unique, multi-pathway approach to ensure lasting results. This treatment targets the root causes of hair loss and promotes a healthier scalp, resulting in thicker, stronger, and revitalized hair.<br /> <br />
    </Text>






            
            
            
           
           
         
          </Col>
        </Row>




        
    
              
            
           
           
      </Container>
      </Section>
  </>
);

export default PRPhairBeardContPage;
